<template>
  <div>
    <!-- Título principal -->
    <div class="d-flex align-center justify-space-between mb-10 my-8">
      <v-btn icon rounded @click="$router.go(-1)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dense v-bind="attrs" v-on="on">
              <v-icon size="28">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Volver</span>
        </v-tooltip>
      </v-btn>
      <h1 class="justify-center">Productos y servicios</h1>
      <div class="d-flex justify-center">
        <v-btn @click="
        (activeCreateProdu = true),
        (activeEditProdu = false),
        (activeUpdateImport = false)
        " color="primary rounded-lg text-capitalize">Agregar
        </v-btn>
        <v-btn color="primary" outlined @click="btnUpdateMasivo" class="mx-2 rounded-lg text-capitalize">Actualizar
          masivo</v-btn>
      </div>
    </div>
    <v-card class="my-3 pa-3 rounded-xl" min-height="67vh">
      <div class="ma-3">
        <!-- lista de producto -->
        <div v-if="!activeCreateProdu && !activeEditProdu && !activeUpdateImport">
          <div class="my-6 d-flex">
            <h3>Lista de productos y servicios existentes</h3>
            <v-spacer></v-spacer>
            <div class="d-flex">
              <v-autocomplete outlined dense :items="familias" item-text="Descripcion" item-value="Descripcion"
                v-model="selectFilterFamiliaExport" label="Filtrar por familia"></v-autocomplete>
              <GeneratePdfPrecios :produsFilter="produsFilter" />
            </div>
          </div>
          <div class="d-flex">
            <v-text-field v-model="searchProdu" label="Buscar por nombre o codigo" outlined></v-text-field>
            <v-btn color="primary" class="mt-2 ml-2" @click="btnBuscarProduByNameOrCode">Buscar</v-btn>
          </div>
          <v-data-table :search="searchProdu" :loading="activeLoadingCargarProdu" :items="produs"
            :headers="headersProdu">
            <template v-slot:item.Vtadeta="{ item }">
              $ {{ item.Vtadeta | formatCurrency }}
            </template>
            <template v-slot:item.Totalmesunit="{ item }">
              {{ item.Totalmesunit }} %
            </template>
            <template v-slot:item.action="{ item }">
              <div class="actions pa-1">
                <v-btn icon color="primary" class="rounded-lg text-capitalize"
                  @click="btnEditProdu(item)"><v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
                <v-btn icon color="primary" class="rounded-lg text-capitalize" :loading="activeLoadingDeleteProdu"
                  @click="btnDeleteProdu(item)"><v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
        <div v-if="activeCreateProdu || activeEditProdu">
          <div class="d-flex">
            <div>
              <h3 v-if="activeCreateProdu" class="my-2">
                Crear nuevo producto o servicio
              </h3>
              <h3 v-if="activeEditProdu" class="my-2">
                Editar producto o servicio
              </h3>
            </div>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon dense v-bind="attrs" v-on="on" @click="
        (activeCreateProdu = false), (activeEditProdu = false)
        ">
                  <v-icon size="28">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Cerrar</span>
            </v-tooltip>
          </div>
          <!-- formulario -->
          <div>
            <v-row>
              <!-- datos del producto -->
              <v-col>
                <div v-if="activeCreateProdu">
                  <v-text-field dense class="ma-1" label="Código" outlined :maxlength="20"
                    :rules="[max20Chars, codigoExists]" v-model="codigo"></v-text-field>

                  <v-alert v-if="mostrarAlerta" dense type="error" class="ma-1">
                    {{ mensajeAlerta }}
                  </v-alert>
                </div>
                <div v-if="activeEditProdu">
                  <v-text-field dense class="ma-1" label="Código" outlined readonly
                    v-model="produ.Codigo"></v-text-field>
                </div>
                <!--nombre del produ  -->
                <v-text-field dense class="ma-1" v-model="produ.Descripcion" label="Nombre del producto o servicio"
                  outlined></v-text-field>
                <!-- familias -->
                <div class="my-1">
                  <v-autocomplete class="ma-1" label="Familia" outlined v-model="produ.Ubicacion"
                    :loading="activeLoadingCargarFamilias" :items="familias" item-text="Descripcion"
                    item-value="Descripcion" dense></v-autocomplete>
                </div>
                <div class="d-flex my-1">
                  <v-text-field dense label="Stock critico" outlined v-model="produ.Stockm" class="ml-1 mr-2 mt-3"
                    type="number"></v-text-field>
                  <v-select label="Unidad de medida" :items="medidas" v-model="produ.Unid" class="ml-1 mr-2 mt-3"
                    outlined dense></v-select>
                  <v-checkbox v-model="tipoImpuestoProdu" label="Afecta inventario"></v-checkbox>
                </div>

                <v-file-input accept="image/*" label="Imagen del producto" outlined counter v-model="imgprodusrc"
                  prepend-icon="mdi-file-image-outline" :show-size="1000" class="border-edit ma-1 my-2" height="100%">
                  <template v-slot:selection="{ text }">
                    <v-avatar tile size="150">
                      <img v-if="imgprodusrc" :src="getUrlVariant()" />
                      <v-chip color="primary" dark label small>
                        {{ text }}
                      </v-chip>
                    </v-avatar>
                  </template>
                </v-file-input>
              </v-col>
              <v-divider vertical class="mx-2"></v-divider>
              <!-- valores del propducto -->
              <v-col>
                <h4>Valores</h4>
                <div class="d-flex my-1">
                  <v-text-field outlined v-model="produ.Costo" dense label="Costo neto sin iva" type="number" min="0"
                    class="ma-1"></v-text-field>
                  <v-text-field outlined dense v-model="produ.Vtadeta" min="0" label="Precio de venta PVP con iva"
                    type="number" class="ma-1"></v-text-field>
                </div>
                <div class="d-flex my-1">
                  <v-text-field outlined dense v-model="produ.L2" min="0" label="Precio de lista 2" type="number"
                    class="ma-1"></v-text-field>
                </div>
                <div class="d-flex my-1">
                  <v-text-field outlined dense label="% de impuesto" v-model="produ.Totalmesunit" type="number"
                    class="ma-1"></v-text-field>
                </div>
                <div class="d-flex my-1">
                  <v-text-field outlined dense v-model="produ.Saldoinit" label="Puntos de premio" min="0" type="number"
                    class="ma-1"></v-text-field>
                  <v-text-field outlined dense v-model="produ.Rentacum" label="Comision por venta en %" type="number"
                    class="ma-1"></v-text-field>
                </div>
                <div class="d-flex my-1 justify-end">
                  <v-btn v-if="activeCreateProdu" :loading="activeLoadingCreateProdu" color="primary" class="my-2"
                    @click="btnCrearNuevoProdu">Crear</v-btn>
                  <v-btn v-if="activeEditProdu" :loading="activeLoadingCreateProdu" color="primary" class="my-2"
                    @click="btnActualizarProdu">Actualizar</v-btn>
                </div>
              </v-col>
            </v-row>

          </div>
        </div>
        <div v-if="activeUpdateImport">
          <h3 class="my-4">Actualizar por importacion</h3>
          <div class="d-flex my-4">
            <h3 class="mr-2 mt-2">Filtrar por familia</h3>
            <v-autocomplete outlined dense :items="familiasProdus" v-model="selectFilterFamiliaExport"
              label="Filtrar por familia"></v-autocomplete>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" color="primary" outlined @click="btnExportExcelProdus">
              <div class="d-flex">
                <v-icon>mdi-microsoft-excel</v-icon>
                <div class="mt-1">Descargar excel</div>
              </div>
            </v-btn>
          </div>
          <v-file-input label="Agrergar archivo formato csv, xls" outlined counter multiple v-model="files"
            :show-size="1000" @change="importFile" class="border-edit ma-1" height="220px">
            <template v-slot:selection="{ index, text }">
              <v-avatar tile size="200" class="d-flex flex-column">
                <v-icon size="100">mdi-microsoft-excel</v-icon>
                <v-chip color="primary" dark label small>
                  {{ text }}
                </v-chip>
              </v-avatar>
            </template>
          </v-file-input>
          <v-alert type="info" class="my-2" color="primary" outlined>Al realizar la actualizacion por importacion desde
            un
            archivo debe
            tener el mismo formato del archivo de descarga y considere los
            siguientes puntos, no puede modificar la estructura del archivo y no
            puede cambiar los códigos de los productos, esto generará un error y
            no será considerado el archivo al cargar</v-alert>
          <div class="d-flex my-4">
            <v-text-field v-model="searchProdusImport" label="Buscar en importados" outlined></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="btnProcesarImportProdus">Actualizar desde archivo</v-btn>
          </div>
          <v-row class="my-2 d-flex justify-center">
            <v-col>
              <h2 class="text-center">Total {{ statsProdusImport.total }}</h2>
            </v-col>
            <v-col>
              <h2 class="text-center">
                Valido {{ statsProdusImport.valido }}
              </h2>
            </v-col>
            <v-col>
              <h2 class="text-center">
                Invalido {{ statsProdusImport.invalido }}
              </h2>
            </v-col>
          </v-row>
          <v-row class="my-2 d-flex justify-center">
            <v-col>
              <h2 class="text-center">
                Actualizar {{ statsProdusImport.actualizar }}
              </h2>
            </v-col>
            <v-col>
              <h2 class="text-center">
                Ingresar {{ statsProdusImport.ingresar }}
              </h2>
            </v-col>
            <v-col>
              <h2 class="text-center">
                Eliminar {{ statsProdusImport.eliminar }}
              </h2>
            </v-col>
            <v-col>
              <h2 class="text-center">
                Cambiar codigo {{ statsProdusImport.nuevocod }}
              </h2>
            </v-col>
            <v-col>
              <h2 class="text-center">
                Sin acción {{ statsProdusImport.sinaccion }}
              </h2>
            </v-col>
          </v-row>

          <v-alert v-if="statsProdusImport.invalido != 0" color="red" class="my-2" type="info" outlined>Existen
            productos
            con errores, estos no serán actualizados</v-alert>

          <v-data-table :items="produsImport" :search="searchProdusImport" :headers="headersProdusImport">
            <template v-slot:item.Costo="{ item }">
              <div>$ {{ item.Costo | formatCurrency }}</div>
            </template>
            <template v-slot:item.Precio="{ item }">
              <div>$ {{ item.Precio | formatCurrency }}</div>
            </template>
          </v-data-table>
          <v-dialog persistent scrollable v-model="activeDialogoImportProdu" max-width="700px">
            <v-card class="rounded-xl pa-6">
              <div class="d-flex">
                <h4>Estado de la importación</h4>
                <v-progress-circular :width="3" color="primary" v-if="activeLoadingImportProdu"
                  v-model="activeLoadingImportProdu" class="mx-3" indeterminate></v-progress-circular>
                <v-spacer></v-spacer>
                <v-btn outlined color="primary" :disabled="activeLoadingImportProdu"
                  @click="activeDialogoImportProdu = false">Cerrar</v-btn>
              </div>
              <v-alert type="info" class="my-3" v-if="!activeLoadingImportProdu" color="primary">Realizado con éxito, en
                caso de existir errores debe
                solucionarlos</v-alert>
              <div v-if="errorsImport.length > 0">
                <h4>Lista de errores</h4>
                <v-alert v-for="(err, index) in errorsImport" :key="index" color="red" outlined class="my-1"
                  type="info">
                  Codigo: {{ err.Codigo }} / Msg: {{ err.Error }}
                </v-alert>
              </div>
            </v-card>
          </v-dialog>
        </div>
        <v-snackbar color="primary" v-model="successCreateProdu" multiLine>
          Producto creado con éxito
        </v-snackbar>
        <v-snackbar color="primary" v-model="successDeleteProdu" multiLine>
          Producto eliminado con éxito
        </v-snackbar>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import GeneratePdfPrecios from "../sales/components/GeneratePdfPrecios.vue";
export default {
  components: {
    GeneratePdfPrecios,
  },
  data() {
    return {
      searchProdu: "",
      headersProdu: [
        { text: "Producto o servicio", value: "Descripcion" },
        { text: "Codigo", value: "Codigo" },
        { text: "Familia", value: "Ubicacion" },
        { text: "Precio", value: "Vtadeta" },
        { text: "Inventario", value: "Afectainv" },
        { text: "Impuesto", value: "Totalmesunit" },
        { text: "Editar", value: "action" },
      ],
      selectFilterFamiliaExport: "",
      activeUpdateImport: false,
      activeCreateProdu: false,
      activeEditProdu: false,
      selectProduEdit: "",
      imgprodusrc: "",
      familiasProdus: [],
      produ: {
        Codigo: "",
        Descripcion: "",
        Costo: 0,
        Saldoinit: 0,
        Fecha: "",
        Vtamayor: 0,
        Vtadeta: 0,
        Afectainv: "",
        Stockm: 0,
        Unid: "0",
        Ubicacion: "",
        Duracion: 0,
        Totalmesunit: 0,
        Totalmesvta: 0,
        Totalacumuni: 0,
        Totalacumvta: 0,
        Rentmes: 0,
        Rentacum: 0,
        Costomes: 0,
        Costoacum: 0,
        Subfamilia: "Sub Familia",
        Id: 0,
        Web: 0,
        L2: "0",
        Indice: 0,
        Especie: ".",
        Imagen: "n",
        Precioweb: 0,
        Especialidad: "Especialidad",
      },
      errorsImport: [],
      searchProdusImport: "",
      headersProdusImport: [
        { text: "Código", value: "Codigo" },
        { text: "Producto o servicio", value: "Nombre" },
        { text: "Costo", value: "Costo" },
        { text: "Precio", value: "Precio" },
        { text: "Familia", value: "Ubicacion" },
        { text: "Accion", value: "Accion" },
        { text: "Stock minimo", value: "Stockm" },
        { text: "Nuevo codigo", value: "Nuevocod" },
        { text: "Inventario", value: "Afectainv" },
        { text: "Precio web", value: "Precioweb" },
        { text: "Especialidad", value: "Especialidad" },
        { text: "Error", value: "Error" },
      ],
      medidas: ["UN", "CC", "ML", "KL", "GRS"],
      codigo: "",
      mensajeAlerta: false,
      mostrarAlerta: false,
      tipoImpuestoProdu: false,
      activeLoadingCreateProdu: false,
      activeLoadingDeleteProdu: false,
      produsFilter: [],
      files: [],
      readers: [],
      produsImport: [],
      statsProdusImport: {
        total: 0,
        valido: 0,
        invalido: 0,
        actualizar: 0,
        eliminar: 0,
        nuevocod: 0,
        ingresar: 0,
        sinaccion: 0,
        errores: [],
      },
      activeDialogoImportProdu: false,
    };
  },
  watch: {
    successCreateProdu: async function (val) {
      if (val) {

        this.activeLoadingCreateProdu = false;
        this.produ = {
          Codigo: "",
          Descripcion: "",
          Costo: 0,
          Saldoinit: 0,
          Fecha: "",
          Vtamayor: 0,
          Vtadeta: 0,
          Afectainv: "",
          Stockm: 0,
          Unid: "0",
          Ubicacion: "",
          Duracion: 0,
          Totalmesunit: 0,
          Totalmesvta: 0,
          Totalacumuni: 0,
          Totalacumvta: 0,
          Rentmes: 0,
          Rentacum: 0,
          Costomes: 0,
          Costoacum: 0,
          Subfamilia: "Sub Familia",
          Id: 0,
          Web: 0,
          L2: "0",
          Indice: 0,
          Especie: ".",
          Imagen: "n",
          Precioweb: 0,
          Especialidad: "Especialidad",
        };
        await this.getProdusByNameOrCode({ search: this.codigo });
        this.codigo = "";
        this.activeCreateProdu = false;
        this.activeEditProdu = false;
      }
    },
    successDeleteProdu: function (val) {
      if (val) {
        this.activeLoadingDeleteProdu = false;
      }
    },
    produs: function (val) {
      if (val.length > 0) {
        this.setDefaultData();
      }
    },
    selectFilterFamiliaExport: async function (val) {
      if (val) {
        this.searchProdu = ""
        await this.btnSearchProdusByFamilia(val);


      }
    },
    activeLoadingImportProdu: function (val) {
      if (val) {
        this.produsImport = [];
        this.files = [];
        this.readers = [];
        this.statsProdusImport = {
          total: 0,
          valido: 0,
          invalido: 0,
          actualizar: 0,
          eliminar: 0,
          nuevocod: 0,
          ingresar: 0,
          sinaccion: 0,
          errores: [],
        };
      }
    },
  },
  computed: {
    ...mapState("medical-module", [
      "produs",
      "activeLoadingCargarProdu",
      "activeLoadingCargarFamilias",
      "familias",
      "successCreateProdu",
      "successDeleteProdu",
      "activeLoadingImportProdu",
    ]),
  },

  methods: {
    ...mapActions("medical-module", [
      "getFamilias",
      "postProdu",
      "deleteProdu",
      "postProduImportFile",
      "getProdusByFamilia",
      "getProdusByNameOrCode"
    ]),
    btnExportExcelProdus: function () {
      let set_sheet = [];
      this.produsFilter.forEach((element) => {
        let setSheet = {};
        setSheet.Codigo = element.Codigo;
        setSheet.Descripcion = element.Descripcion;
        setSheet.Costo = element.Costo;
        setSheet.Precio = element.Vtadeta;
        setSheet.Familia = element.Ubicacion;
        setSheet.Accion = "";
        setSheet.Stockmn = element.Stockm;
        setSheet.Newcod = "";
        setSheet.Afectainv = element.Afectainv;
        setSheet.Precioweb = element.Precioweb;
        setSheet.Especialidad = element.Especialidad;
        set_sheet.push(setSheet);
      });
      let data = XLSX.utils.json_to_sheet(set_sheet, {
        header: [
          "Codigo",
          "Descripcion",
          "Costo",
          "Precio",
          "Familia",
          "Accion",
          "Stockmn",
          "Newcod",
          "Afectainv",
          "Precioweb",
          "Especialidad",
        ],
      });
      data["A1"].v = "Codigo";
      data["B1"].v = "Descripcion";
      data["C1"].v = "Costo";
      data["D1"].v = "Precio";
      data["E1"].v = "Familia";
      data["F1"].v = "Accion";
      data["G1"].v = "Stock minimo";
      data["H1"].v = "Nuevo codigo";
      data["I1"].v = "Afecta inventario";
      data["J1"].v = "Precio web";
      data["K1"].v = "Especialidad";

      const workbook = XLSX.utils.book_new();
      let today_text = new Date().toISOString().substring(0, 10);
      const filename = "productos";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    processExcel(data) {
      var workbook = XLSX.read(data, {
        type: "binary",
      });

      var firstSheet = workbook.SheetNames[0];
      var data = this.to_json(workbook);
      return data;
    },
    loadFamilias() {
      this.getFamilias();
    },

    to_json(workbook) {
      var result = {};
      workbook.SheetNames.forEach(function (sheetName) {
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });
        if (roa.length) result[sheetName] = roa;
      });
      return JSON.stringify(result, 2, 2);
    },
    btnEditProdu(produ) {
      this.selectProduEdit = produ;
      this.produ = this.produs.find((item) => item.Codigo == produ.Codigo);
      if (this.produ.Afectainv == "SI") {
        this.tipoImpuestoProdu = true;
      }
      this.produ.Fecha = this.produ.Fecha.substring(0, 10);
      this.activeCreateProdu = false;
      this.activeEditProdu = true;
    },
    setDefaultData() {
      this.produsFilter = this.produs;
    },
    async btnSearchProdusByFamilia(familia) {
      await this.getProdusByFamilia({ search: familia })
      this.produsFilter = [];


      this.produsFilter = this.produs
    },
    importFile() {
      this.produsImport = [];
      this.statsProdusImport.errores = [];
      this.statsProdusImport.valido = 0;
      this.statsProdusImport.invalido = 0;
      this.statsProdusImport.total = 0;
      this.files.forEach((file, f) => {
        this.readers[f] = new FileReader();
        this.readers[f].onload = (e) => {
          var contents = this.processExcel(this.readers[f].result);
          let dataSet = JSON.parse(contents);
          let setArray = dataSet[Object.keys(dataSet)[0]];
          this.dataDocs = setArray.filter((element, index) => index > 0);
          this.dataDocs.forEach((item) => {
            let produ_set = {
              Codigo: "",
              Nombre: "",
              Costo: 0,
              Precio: 0,
              Ubicacion: "",
              Accion: "",
              Stockm: 0,
              Nuevocod: 0,
              Afectainv: "",
              Precioweb: 0,
              Especialidad: "",
              Error: "",
            };
            produ_set.Codigo = item[0];
            produ_set.Nombre = item[1];
            produ_set.Ubicacion = item[4];
            if (item[2]) {
              if (typeof item[2] === "string") {
                produ_set.Costo = parseInt(item[2]);
              } else {
                produ_set.Costo = item[2];
              }
            }
            if (item[3]) {
              if (typeof item[3] === "string") {
                produ_set.Precio = parseInt(item[3]);
              } else {
                produ_set.Precio = item[3];
              }
            }
            let find = this.validateCodProdu(produ_set.Codigo);
            if (find == -1) {
              if (produ_set.Accion == "A" || produ_set.Accion == "a" ||
                produ_set.Accion == "E" || produ_set.Accion == "e" ||
                produ_set.Accion == "C" || produ_set.Accion == "c") {
                this.statsProdusImport.errores.push({
                  codigo: produ_set.Codigo,
                  error: "Código inexistente",
                });
                this.statsProdusImport.invalido += 1

              } else {
                this.statsProdusImport.valido += 1;
              }
            } else {
              this.statsProdusImport.valido += 1;
            }
            produ_set.Accion = item[5];
            if (item[6]) {
              if (typeof item[6] === "string") {
                produ_set.Stockm = parseInt(item[6]);
              } else {
                produ_set.Stockm = item[6];
              }
            }
            if (item[7]) {
              if (typeof item[7] === "number") {
                if (item[7] != "0") {
                  produ_set.Nuevocod = item[7].toString();
                }
              } else {
                produ_set.Nuevocod = item[7];
              }
            }
            produ_set.Afectainv = item[8];
            if (item[9]) {
              if (typeof item[9] === "string") {
                produ_set.Precioweb = parseInt(item[9]);
              } else {
                produ_set.Precioweb = item[9];
              }
            }
            switch (produ_set.Accion) {
              case "A":
                this.statsProdusImport.actualizar += 1;
                break;
              case "E":
                this.statsProdusImport.eliminar += 1;
                break;
              case "C":
                this.statsProdusImport.nuevocod += 1;
                break;
              case "I":
                this.statsProdusImport.ingresar += 1;
                break;
              default:
                this.statsProdusImport.sinaccion += 1;

                break;
            }
            produ_set.Especialidad = item[10];
            this.statsProdusImport.total += 1;
            this.produsImport.push(produ_set);
          });
          // send to server here...
        };

        this.readers[f].readAsBinaryString(this.files[f]);
      });
    },
    validateCodProdu(cod) {
      let index = -1;
      index = this.produs.findIndex((item) => item.Codigo == cod);
      return index;
    },
    btnCerrarEditProdu() {
      this.selectProduEdit = "";
      this.activeCreateProdu = false;
      this.activeEditProdu = false;
    },
    getUrlVariant() {
      let uri = URL.createObjectURL(this.imgprodusrc);
      return uri;
    },
    max20Chars(value) {
      if (value && value.length > 20) {
        this.mostrarAlerta = true;
        this.mensajeAlerta = "El código es mayor a 20 caracteres";
        return "error";
      } else {
        this.mostrarAlerta = false;
      }
      return true;
    },
    codigoExists(value) {
      if (value && this.produs.some((prod) => prod.Codigo === value)) {
        this.mostrarAlerta = true;
        this.mensajeAlerta = "El código ya existe";
        return "error";
      }

      return true;
    },
    btnUpdateMasivo() {
      this.activeUpdateImport = true;
      this.activeCreateProdu = false;
      this.activeEditProdu = false;
    },
    setFamiliasProdus() {
      this.familiasProdus = this.familias.map((item) => item.Descripcion);
    },
    async btnCrearNuevoProdu() {
      if (this.mostrarAlerta) {
        alert("No es posible crear el producto, tiene un error en el codigo");
        return;
      }
      if (!this.produ.Descripcion) {
        alert("El producto o servicio requiere una descripcion");
        return;
      }
      this.activeLoadingCreateProdu = true;
      if (this.tipoImpuestoProdu) {
        this.produ.Afectainv = "SI";
      } else {
        this.produ.Afectainv = "NO";
      }
      if (!this.codigo) {
        alert("El producto o servicio requiere un codigo");
        return;
      }

      this.produ.Codigo = this.codigo;
      this.produ.Fecha = new Date().toISOString().substring(0, 10);
      if (typeof this.produ.Costo === "string") {
        this.produ.Costo = parseInt(this.produ.Costo);
      }

      if (typeof this.produ.Saldoinit === "string") {
        this.produ.Saldoinit = parseInt(this.produ.Saldoinit);
      }

      if (typeof this.produ.Vtamayor === "string") {
        this.produ.Vtamayor = parseInt(this.produ.Vtamayor);
      }

      if (typeof this.produ.Vtadeta === "string") {
        this.produ.Vtadeta = parseInt(this.produ.Vtadeta);
      }

      if (typeof this.produ.Stockm === "string") {
        this.produ.Stockm = parseInt(this.produ.Stockm);
      }

      if (typeof this.produ.Duracion === "string") {
        this.produ.Duracion = parseInt(this.produ.Duracion);
      }

      if (typeof this.produ.Totalmesunit === "string") {
        this.produ.Totalmesunit = parseInt(this.produ.Totalmesunit);
      }

      if (typeof this.produ.Totalmesvta === "string") {
        this.produ.Totalmesvta = parseInt(this.produ.Totalmesvta);
      }

      if (typeof this.produ.Totalacumuni === "string") {
        this.produ.Totalacumuni = parseInt(this.produ.Totalacumuni);
      }

      if (typeof this.produ.Totalacumvta === "string") {
        this.produ.Totalacumvta = parseInt(this.produ.Totalacumvta);
      }

      if (typeof this.produ.Rentmes === "string") {
        this.produ.Rentmes = parseInt(this.produ.Rentmes);
      }

      if (typeof this.produ.Rentacum === "string") {
        this.produ.Rentacum = parseInt(this.produ.Rentacum);
      }

      if (typeof this.produ.Costomes === "string") {
        this.produ.Costomes = parseInt(this.produ.Costomes);
      }

      if (typeof this.produ.Costoacum === "string") {
        this.produ.Costoacum = parseInt(this.produ.Costoacum);
      }

      if (typeof this.produ.Web === "string") {
        this.produ.Web = parseInt(this.produ.Web);
      }

      if (typeof this.produ.Id === "string") {
        this.produ.Id = parseInt(this.produ.Id);
      }

      if (typeof this.produ.Indice === "string") {
        this.produ.Indice = parseInt(this.produ.Indice);
      }

      if (typeof this.produ.Precioweb === "string") {
        this.produ.Precioweb = parseInt(this.produ.Precioweb);
      }

      await this.postProdu({ produ: this.produ, tipo: "create" });
    },
    btnActualizarProdu() {
      this.activeLoadingCreateProdu = true;
      if (!this.produ.Codigo) {
        this.activeLoadingCreateProdu = false;
        alert("No hay seleccionado producto para actualizar");
        return;
      }
      if (this.tipoImpuestoProdu) {
        this.produ.Afectainv = "SI";
      } else {
        this.produ.Afectainv = "NO";
      }
      if (typeof this.produ.Costo === "string") {
        this.produ.Costo = parseInt(this.produ.Costo);
      }

      if (typeof this.produ.Saldoinit === "string") {
        this.produ.Saldoinit = parseInt(this.produ.Saldoinit);
      }

      if (typeof this.produ.Vtamayor === "string") {
        this.produ.Vtamayor = parseInt(this.produ.Vtamayor);
      }

      if (typeof this.produ.Vtadeta === "string") {
        this.produ.Vtadeta = parseInt(this.produ.Vtadeta);
      }

      if (typeof this.produ.Stockm === "string") {
        this.produ.Stockm = parseInt(this.produ.Stockm);
      }

      if (typeof this.produ.Duracion === "string") {
        this.produ.Duracion = parseInt(this.produ.Duracion);
      }

      if (typeof this.produ.Totalmesunit === "string") {
        this.produ.Totalmesunit = parseInt(this.produ.Totalmesunit);
      }

      if (typeof this.produ.Totalmesvta === "string") {
        this.produ.Totalmesvta = parseInt(this.produ.Totalmesvta);
      }

      if (typeof this.produ.Totalacumuni === "string") {
        this.produ.Totalacumuni = parseInt(this.produ.Totalacumuni);
      }

      if (typeof this.produ.Totalacumvta === "string") {
        this.produ.Totalacumvta = parseInt(this.produ.Totalacumvta);
      }

      if (typeof this.produ.Rentmes === "string") {
        this.produ.Rentmes = parseInt(this.produ.Rentmes);
      }

      if (typeof this.produ.Rentacum === "string") {
        this.produ.Rentacum = parseInt(this.produ.Rentacum);
      }

      if (typeof this.produ.Costomes === "string") {
        this.produ.Costomes = parseInt(this.produ.Costomes);
      }

      if (typeof this.produ.Costoacum === "string") {
        this.produ.Costoacum = parseInt(this.produ.Costoacum);
      }

      if (typeof this.produ.Web === "string") {
        this.produ.Web = parseInt(this.produ.Web);
      }

      if (typeof this.produ.Id === "string") {
        this.produ.Id = parseInt(this.produ.Id);
      }

      if (typeof this.produ.Indice === "string") {
        this.produ.Indice = parseInt(this.produ.Indice);
      }

      if (typeof this.produ.Precioweb === "string") {
        this.produ.Precioweb = parseInt(this.produ.Precioweb);
      }

      this.postProdu({ produ: this.produ, tipo: "update" });
    },
    async btnDeleteProdu(produ) {
      if (window.confirm("¿Está seguro de eliminar el producto " + produ.Descripcion + "?")) {
        this.activeLoadingDeleteProdu = true;
        await this.deleteProdu({ id: produ.Id });
      }
    },
    buscarProduById(cod) {
      let produSearch = null;
      produSearch = this.produs.find((item) => item.Codigo == cod);
      return produSearch;
    },
    async btnBuscarProduByNameOrCode() {
      await this.getProdusByNameOrCode({ search: this.searchProdu });
    },
    btnProcesarImportProdus() {
      let produActualizar = [];
      let produEliminar = [];
      let produCambiarCod = [];
      let produIngresar = [];
      let datosCarga = {
        produActualizar: [],
        produEliminar: [],
        produCambiarCod: [],
        produIngresar: [],
      };
      if (this.produsImport.length < 1) {
        alert("No hay productos para procesar");
        return;
      }
      let errorsImport = [];
      this.produsImport.forEach((element) => {
        switch (element.Accion) {
          case "A" || "a":
            let searchActualizar = this.buscarProduById(element.Codigo);
            if (searchActualizar) {
              searchActualizar.Descripcion = element.Nombre;
              searchActualizar.Afectainv = element.Afectainv;
              searchActualizar.Especialidad = element.Especialidad;
              searchActualizar.Vtadeta = element.Precio;
              searchActualizar.Fecha = new Date()
                .toISOString()
                .substring(0, 10);
              searchActualizar.Precioweb = element.Precioweb;
              searchActualizar.Stockm = element.Stockm;
              searchActualizar.Ubicacion = element.Ubicacion;
              produActualizar.push(searchActualizar);
            } else {
              errorsImport.push({
                Codigo: element.Codigo,
                Error:
                  "No existe el codigo en los productos existentes para actualizar",
              });
            }

            break;
          case "C" || "c":
            let searchCambiarCodigo = this.buscarProduById(element.Codigo);
            if (searchCambiarCodigo) {
              searchCambiarCodigo.Codigo = element.Nuevocod;
              produCambiarCod.push(searchCambiarCodigo);
            } else {
              errorsImport.push({
                Codigo: element.Codigo,
                Error:
                  "No existe el codigo en los productos existentes para cambiar el codigo",
              });
            }
            break;
          case "I" || "i":
            let searchProduIngresar = this.buscarProduById(element.Codigo);
            if (searchProduIngresar) {
              errorsImport.push({
                Codigo: element.Codigo,
                Error:
                  "No puede crear el producto, debido a que ya existe con el código",
              });
            } else {
              let newProdu = {
                Codigo: "",
                Descripcion: "",
                Costo: 0,
                Saldoinit: 0,
                Fecha: new Date().toISOString().substring(0, 10),
                Vtamayor: 0,
                Vtadeta: 0,
                Afectainv: "",
                Stockm: 0,
                Unid: "un",
                Ubicacion: "",
                Duracion: 0,
                Totalmesunit: 0,
                Totalmesvta: 0,
                Totalacumuni: 0,
                Totalacumvta: 0,
                Rentmes: 0,
                Rentacum: 0,
                Costomes: 0,
                Costoacum: 0,
                Subfamilia: "Sub Familia",
                Id: 0,
                Web: 0,
                L2: "0",
                Indice: 0,
                Especie: ".",
                Imagen: "n",
                Precioweb: 0,
                Especialidad: "Especialidad",
              };
              newProdu.Codigo = element.Codigo;
              newProdu.Descripcion = element.Nombre;
              newProdu.Afectainv = element.Afectainv;
              newProdu.Especialidad = element.Especialidad;
              newProdu.Vtadeta = element.Precio;
              newProdu.Precioweb = element.Precioweb;
              newProdu.Stockm = element.Stockm;
              newProdu.Ubicacion = element.Ubicacion;
              produIngresar.push(newProdu);
            }
            break;
          case "E" || "e":
            let searchProduEliminar = this.buscarProduById(element.Codigo);
            if (searchProduEliminar) {
              produEliminar.push(searchProduEliminar);
            } else {
              errorsImport.push({
                Codigo: element.Codigo,
                Error:
                  "No existe el codigo en los productos existentes para eliminar",
              });
            }

            break;
        }
      });
      datosCarga.produActualizar = produActualizar;
      datosCarga.produCambiarCod = produCambiarCod;
      datosCarga.produEliminar = produEliminar;
      datosCarga.produIngresar = produIngresar;
      this.postProduImportFile({ produ: datosCarga });
      this.errorsImport = errorsImport;
      this.activeDialogoImportProdu = true;
    },
  },
  created() {
    this.getFamilias();
    this.setFamiliasProdus();
    this.setDefaultData();
  },
};
</script>
<style lang=""></style>

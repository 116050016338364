<template>
  <div>
    <!-- Documento a imprimir -->
    <v-btn
      class="mx-2"
      color="primary"
      outlined
      @click="btnGeneratePdfFileVtaser"
    >
      <div class="d-flex">
        <v-icon>mdi-file-pdf-box</v-icon>
        <div class="mt-1">Lista precios</div>
      </div>
    </v-btn>
    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="864"
        filename="listaprecios"
        :pdf-quality="0.8"
        :manual-pagination="false"
        pdf-format="letter"
        pdf-orientation="portrait"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        @after-render="addPageBreaks"
        :html-to-pdf-options="htmlToPdfOptions"
        ref="generatePreciosPdf"
      >
        <section slot="pdf-content">
          <div class="page-margin">
            <h3 class="text-center">Lista de precios</h3>
            <div>
              <div>{{ clinica.Nombre }}</div>
              <div>{{ clinica.Direccion }} {{ clinica.Ciudad }}</div>
              <div>{{ clinica.Fono }}</div>
              <div>Sucursal: {{ user.Sucursal }}</div>
              <div>
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </div>
              <div class="content-title-vta">
                <div class="content-title-produ-codigo">Codigo</div>
                <div class="content-title-produ-produ">Nombre</div>
                <div class="content-title-produ-precio">Precio</div>
                <div class="content-title-produ-famialia">Familia</div>
                <div class="content-title-vta-nombre"></div>
              </div>
              <div>
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </div>
              <div
                class="content-title-vta"
                v-for="produ in produsFilter"
                :key="produ.Id"
              >
                <div class="content-title-produ-codigo">
                  {{ produ.Codigo }}
                </div>
                <div class="content-title-produ-produ">
                  {{ produ.Descripcion }}
                </div>
                <div class="content-title-produ-precio">
                  {{ produ.Vtadeta }}
                </div>
                <div class="content-title-produ-famialia">
                  {{ produ.Ubicacion }}
                </div>
              </div>
              <div>
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "generatePreciosPdf",
  components: {
    VueHtml2pdf,
  },
  props: {
    produsFilter: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      today: new Date().toISOString(),
      htmlToPdfOptions: {
        margin: 0.1,

        filename: `listaprecios.pdf`,

        image: {
          type: "jpeg",
          quality: 0.5,
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
          useCORS: true,
        },

        jsPDF: {
          unit: "in",
          format: "letter",
          orientation: "portrait",
        },
      },
    };
  },
  methods: {
    addPageBreaks() {
      const pdfContent = this.$refs.generatePreciosPdf.pdfContent;

      const elements = pdfContent.getElementsByClassName("content-title-vta");
      const pageHeight = 1010; // Altura de página establecida en el atributo "paginate-elements-by-height"

      let accumulatedHeight = 0;

      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const elementHeight = element.offsetHeight;

        if (accumulatedHeight + elementHeight > pageHeight) {
          const clone = element.cloneNode(true);
          clone.style.pageBreakBefore = "always";
          pdfContent.appendChild(clone);
          accumulatedHeight = elementHeight;
        } else {
          accumulatedHeight += elementHeight;
        }
      }
    },
    setDateShortString(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " de " + year;
    },
    btnGeneratePdfFileVtaser() {
      this.$refs.generatePreciosPdf.generatePdf();
    },
    setDateString(date) {
      var dataSet = new Date(date);
      dataSet = this.setDateNow(dataSet);
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year + " a las " + h + ":" + m;
    },
    setDateStringPrint(date) {
      var dataSet = new Date(date);
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return (
        day +
        " de " +
        montTex +
        " del " +
        year +
        " a las " +
        h +
        ":" +
        m +
        " horas"
      );
    },
    setDateStringShortPrint(date) {
      var dataSet = new Date(date + "T00:00:00");
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
    setDateNow(date) {
      var currentDateObj = date;
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    getAge(dateString) {
      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var dob = new Date(
        dateString.substring(0, 4),
        dateString.substring(5, 7) - 1,
        dateString.substring(8, 10)
      );

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      let yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
      else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
      else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
      };

      if (age.years > 1) yearString = " años";
      else yearString = " año";
      if (age.months > 1) monthString = " meses";
      else monthString = " mes";
      if (age.days > 1) dayString = " días";
      else dayString = " día";

      if (age.years > 0 && age.months > 0 && age.days > 0)
        ageString =
          age.years +
          yearString +
          ", " +
          age.months +
          monthString +
          ", y " +
          age.days +
          dayString;
      else if (age.years == 0 && age.months == 0 && age.days > 0)
        ageString = "solo " + age.days + dayString;
      else if (age.years > 0 && age.months == 0 && age.days == 0)
        ageString = age.years + yearString + " Feliz cumpleaños!!";
      else if (age.years > 0 && age.months > 0 && age.days == 0)
        ageString = age.years + yearString + " y " + age.months + monthString;
      else if (age.years == 0 && age.months > 0 && age.days > 0)
        ageString =
          age.months + monthString + " y " + age.days + dayString + ".";
      else if (age.years > 0 && age.months == 0 && age.days > 0)
        ageString = age.years + yearString + " y " + age.days + dayString + ".";
      else if (age.years == 0 && age.months > 0 && age.days == 0)
        ageString = age.months + monthString + " .";
      else ageString = "Oops! No es posible calcular!";

      return ageString;
    },
  },
  computed: {
    ...mapState("sales-module", ["vtasser"]),
    ...mapState("auth-module", ["clinica", "user"]),
    calculateTotal() {
      let total = 0;
      this.vtasser.forEach((element) => {
        total += element.Total;
      });
      return total;
    },
    calculateTotalPagado() {
      let total = 0;
      this.vtasser.forEach((element) => {
        total += element.Montopagado;
      });
      return total;
    },
    onChangeDateVtaser() {
      return this.setDateStringShortPrint(this.dateVtaser);
    },
  },
  created() {},
};
</script>
<style>
/* Contenido receta a4 */
strong {
  font-weight: 800;
  letter-spacing: 2px;
}
.body-vtaser {
  padding: 10px;
}
.text-center {
  text-align: center;
}
.content-title-vta {
  display: flex;
  width: 100%;
}
.content-title-produ-codigo {
  width: 20%;
}
.content-title-produ-produ {
  width: 40%;
}
.content-title-produ-precio {
  width: 15%;
}
.content-title-produ-famialia {
  width: 15%;
}
.page-margin {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}
</style>